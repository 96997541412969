import { PLATFORM } from "aurelia-pal";
import environment from './environment';
import { Router, RouteConfig, PipelineStep, RouterConfiguration, activationStrategy, Redirect, NavigationInstruction, Next, RouterEvent } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { EventAggregator } from "aurelia-event-aggregator";
import { CurrentUser } from "./user";
import { ViewPort, ViewPorts } from "./viewPort";
import { DialogService } from "aurelia-dialog";
import { TokenService } from "./services/tokenService";
import { HttpClient, json } from "aurelia-fetch-client";
require('./style/app.BRAND.scss')

@autoinject()
export class App {

    public router: Router;
    routerStack: Router[] = [];

    public get brand() {
        return environment.brand;
    }


    constructor(public currentUser: CurrentUser,
        public viewPort: ViewPort,
        private eventAgg: EventAggregator,
        private dialogs: DialogService,
        private tokenService: TokenService,
        private client: HttpClient) {
        eventAgg.subscribe(RouterEvent.Processing, () => {
            this.menuOpen = false;
        })
    }

    private menuOpen = false;
    @computedFrom("menuHidable", "menuOpen")
    public get menuVisible() {
        if (this.menuHidable) {
            return this.menuOpen;
        } else {
            return true;
        }
    }

    @computedFrom("viewPort.deviceSize")
    public get menuHidable() {
        return this.viewPort.deviceSize < ViewPorts.laptop;
    }
    @computedFrom("viewPort.deviceSize")
    public get moveProfile() {
        return this.viewPort.deviceSize < ViewPorts.tablet;
    }

    showMenu() {
        this.menuOpen = true;
    }

    hideMenu() {
        this.menuOpen = false;
    }

    get showMyClaimsButton() {
        return this.currentUser.isFullyAuthenticated && this.router.currentInstruction.config.name !== 'home' && !this.currentUser.isAdmin;
    }

    goToHomePage() {
        // Reload page here so we're prompted if unsaved changes
        window.location.href = '/';
    }

    configureRouter(config: RouterConfiguration, router: Router) {
        this.router = router;
        this.routerStack.push(router);

        config.title = 'Claimant Portal';
        config.options.pushState = true;
        config.options.root = '/';

        config.addAuthorizeStep(new GetTokenStep(this.currentUser, this.tokenService, this.client, this.router));
        config.addAuthorizeStep(new AuthorizeStep(this.currentUser));
        config.addPreActivateStep(new DialogStep(this.dialogs));
        config.map([
            // { route: 'login', name: 'login', moduleId: PLATFORM.moduleName('login/index'), settings: { auth: false } },
            // { route: 'forgotten-password', name: 'forgotten-password', moduleId: PLATFORM.moduleName('login/index'), settings: { auth: false } },

            { route: '', name: 'home', moduleId: PLATFORM.moduleName('home/index', 'home'), settings: { auth: false }, nav: true, title: 'Home' },
            { route: 'policy', name: 'policy', moduleId: PLATFORM.moduleName('application/policy', 'policy'), settings: { auth: false }, nav: true, title: 'Policy Information' },
            { route: 'application-form', name: 'application-form', moduleId: PLATFORM.moduleName('application/application-form', 'application-form'), settings: { auth: false }, nav: true, title: 'Application Form' },
            { route: 'submitted', name: 'submitted', moduleId: PLATFORM.moduleName('application/submitted', 'submitted'), settings: { auth: false }, nav: true, title: 'Submitted' },
            { route: 'close-application', name: 'close-application', moduleId: PLATFORM.moduleName('application/close-application', 'close-application'), settings: { auth: false }, nav: true, title: 'Close Application' },
            { route: 'application-closed', name: 'application-closed', moduleId: PLATFORM.moduleName('application/application-closed', 'application-closed'), settings: { auth: false }, nav: true, title: 'Application Closed' },
            { route: 'files', name: 'files', moduleId: PLATFORM.moduleName('files/files', 'files'), settings: { auth: false }, nav: true, title: 'File Download' },
            { route: 'auth', name: 'auth', moduleId: PLATFORM.moduleName('auth/auth', 'auth'), settings: { auth: false }, nav: true, title: 'Please Provide Email Address' }
        ].map((c: RouteConfig) => {
            if (c.settings.auth) {
                //  c.layoutViewModel = PLATFORM.moduleName('layouts/logged-in')
            }
            return c;
        }));
    }
}

class GetTokenStep implements PipelineStep {
    constructor(private currentUser: CurrentUser, private tokenService: TokenService, private client: HttpClient, private router: Router) { }
    async run(instruction: NavigationInstruction, next: Next) {
        if (instruction.queryParams.shared) {
            this.tokenService.setSharedComputer(instruction.queryParams.shared === 'true');
        }

        if (instruction.queryParams.code) {
            let response = await this.client.post("api/token", json(instruction.queryParams.code));
            if (response.ok) {
                let token = await response.text();
                await this.tokenService.setToken(token);
                await this.currentUser.ReloadUser();
            } else {
                let params = { expired: true, documentId: instruction.queryParams.documentId };
                this.router.navigateToRoute("auth", params);
            }
        } else if (instruction.queryParams.token) {
            await this.tokenService.setToken(instruction.queryParams.token);
            await this.currentUser.ReloadUser()
        } else {
            await this.currentUser.LoadUser();
        }


        return next();
    }
}


class DialogStep implements PipelineStep {
    constructor(private dialogs: DialogService) { }
    run(instruction: NavigationInstruction, next: Next) {
        // TODO add logic here to know if logged in or not

        if (this.dialogs.controllers.length > 0) {
            return next.reject();
        }

        return next();
    }
}


class AuthorizeStep {
    constructor(private currentUser: CurrentUser) { }
    run(navigationInstruction, next) {
        if (navigationInstruction.getAllInstructions().some(i => i.config.settings.auth)) {

            // TODO add logic here to know if logged in or not
            var isLoggedIn = this.currentUser.isLoggedIn;

            if (!isLoggedIn) {
                return next.cancel(new Redirect('login'));
            }
        }

        return next();
    }
}

